import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
// import { NgxPaginationModule } from 'ngx-pagination';
import { MainService } from '../services/main.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';


interface ApiItem {
  title: string;
  viewCount: number;
  likeCount: number;
  viewDuration: number;
  category: string;
  timestamp: string;
  country: string;
  actions: string;
  date: string;
  time: string;
  summary: string;
  images: string;
  id: string;
  serialNumber: number;
  deleted: boolean;
  newsType: string;
  created_by: string
}

interface TokenItem {
  token: string
}

interface ApiResponse {
  data: ApiItem[];
}

interface TokenResponse {
  data: TokenItem[];
}

interface Category {
  cat_name: string;
  icon_url: string;
  id: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {

  searchKeyword: string = '';
  apiData: ApiItem[] = [];
  filteredData: ApiItem[] = [];
  pagedData: ApiItem[] = [];
  loggedInUserId: string = ""
  selectedCountry: string = 'usa'; // Default country
  newsSourceUrl: string = '';
  selectedCategory: string = 'none'; // Default category
  currentPage = 1;
  itemsPerPage = 10;
  totalPages = 0;
  isMobileViewVisible = false
  isMobileViewLoadingVisible = false
  uploadedNewsItem: any
  breaking: boolean = false;

  imageUrl: string = 'https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg';
  newsTitle: string = " "
  newsSummary: string = " "
  newsSourceName: string = " "

  isEditViewVisible = false
  editNewsTitle: string = ""
  editNewsSummary: string = ""
  editNewsAuthor: string = ""
  editImageUrl: string = ""
  editNewsCategory: string = ""
  filterText = '';
  filterDate: string = '';
  filterDateOne: string = ''
  tokenData: TokenItem[] = [];
  loadingData: boolean = false;
  categories: Category[];
  glossaries: any = [];
  meaningArr: any = [];

  fg: FormGroup;

  onKeywordChange() {
    this.filteredData = this.apiData.filter(item => {
      const filterTextMatch = item.title.toLowerCase().includes(this.filterText.toLowerCase()) || item.category.toLowerCase().includes(this.filterText.toLowerCase()) || item.deleted === true;
      return filterTextMatch;
    });
    this.assignSerialNumbers()
    // console.log(this.filterText); // Log "hi" to the console when the input field value changes
  }

  filterData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.filteredData = this.apiData.slice(startIndex, startIndex + this.itemsPerPage);
    this.totalPages = Math.ceil(this.apiData.length / this.itemsPerPage);
    this.assignSerialNumbers()
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterData();
  }


  formatViewDuration(viewDuration: number): string {
    const seconds = Math.floor(viewDuration % 60);
    const minutes = Math.floor((viewDuration / 60) % 60);
    const hours = Math.floor(viewDuration / 3600);

    let durationString = '';

    if (hours > 0) {
      durationString += `${hours} hour${hours > 1 ? 's' : ''} `;
    }

    if (minutes > 0 || hours > 0) {
      durationString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }

    durationString += `${seconds} second${seconds !== 1 ? 's' : ''}`;

    return durationString.trim();
  }

  constructor(private http: HttpClient, private datePipe: DatePipe, private service: MainService, private router: Router, private fb: FormBuilder) {
    this.loggedInUserId = 'rahul.d@quidshots.com'
    this.categories = []
    this.loadCategories();
    this.glossaries = [];
    this.fg = this.fb.group({
      meaning: ['']
    });
  }

  ngOnInit() {
    // this.getAllData();

  }

  form = this.fb.group({
    items: this.fb.array([]),
  });

  get usernameControl() {
    return this.fg.get('meaning');
  }

  get items() {
    return this.form.get('items') as FormArray;
  }

  deleteItems(index: number) {
    this.items.removeAt(index);
  }

  addItems() {
    if (this.items.length < 3) {
      this.items.push(
        this.fb.group({
          word: [''],
          meaning: [''],
        })
      );
    }
  }

  applyFilter() {
    this.filteredData = []
    this.getAllData(this.filterDate, this.filterDateOne)
  }

  getAllData(filterDate: string, filterDateOne: string) {
    const apiUrl = `${environment.SERVER_URL}getAll`;

    // Assuming you need to pass some data in the POST request body
    const requestData = {
      fromDate: filterDate,
      toDate: filterDateOne
    };

    this.loadingData = true;

    this.http.post<ApiResponse>(apiUrl, requestData).subscribe({
      next: (response) => {
        this.apiData = response.data;
        this.apiData.forEach(item => {
          const dateTime = new Date(item.timestamp);
          item.date = dateTime.toLocaleDateString();
          item.time = dateTime.toLocaleTimeString();
        });
        this.apiData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        this.filteredData = [...this.apiData.slice(0, 0 + this.itemsPerPage)];

        this.assignSerialNumbers();
        this.loadingData = false;
        // this.updatePagedData();
      },
      error: (error) => {
        console.error('API Error:', error);
        this.loadingData = false;
      }
    });
  }

  submitEditForm() {
    var finalEditedVersion: any = {}

    finalEditedVersion = this.uploadedNewsItem
    finalEditedVersion['images'] = this.editImageUrl
    finalEditedVersion['title'] = this.editNewsTitle
    finalEditedVersion['summary'] = this.editNewsSummary
    finalEditedVersion['news_source_name'] = this.editNewsAuthor
    console.log('this.form.values', this.form.value);
    finalEditedVersion['glossaries'] = this.form.value; 
    finalEditedVersion['category'] = this.deCapitalize(this.editNewsCategory)

    const currentDateTime = new Date();
    const formattedDateTime = this.datePipe.transform(currentDateTime, 'MMM d, y, h:mm:ss a z', 'UTC-4');

    finalEditedVersion['updated_at'] = formattedDateTime
    finalEditedVersion['updated_by'] = this.loggedInUserId

    console.log(finalEditedVersion);

    var requestBody = {
      id: this.uploadedNewsItem.id,
      shot: finalEditedVersion
    }

    console.log('requestBody', requestBody.shot);

    this.service.updateShots(requestBody).subscribe(
      (response: any) => {
        console.log('response', response);
        window.alert("News shots successfully Updated");
        window.location.href = '/admin';
      },
      (error: any) => {
        window.alert("There is some error while updating the shot. please check: \n" + error)
      }
    )
  }


  editShotClicked(item: any) {
    console.log('====================================');
    console.log(item);
    console.log('====================================');
    console.log(item.id)
    this.isEditViewVisible = true
    this.uploadedNewsItem = item
    this.editNewsTitle = item.title
    this.editNewsSummary = item.summary
    this.editNewsAuthor = item.news_source_name
    this.editImageUrl = item.images
    this.glossaries = item.glossaries
    this.editNewsCategory = this.capitalize(item.category)
    if (item.glossaries.items.length > 0){
      item.glossaries.items.map((obj: {word: any; meaning:any}) => {  
        this.items.push(
          this.fb.group({
            word: [obj.word],
            meaning: [obj.meaning],
          })
        );
      })
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    console.log(this.editNewsTitle);

  }

  capitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  deCapitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  assignSerialNumbers() {
    this.filteredData.forEach((item, index) => {
      item.serialNumber = index + 1;
    });
  }

  async notifyItem(item: ApiItem) {

    if (confirm(`Do you want to send a Push Notification \n\n "${item.title}"`)) {
      const FirebaseApiUrl = 'https://fcm.googleapis.com/v1/projects/sapidly-news-application/messages:send';
      const topic = 'news';
      const getTokenUrl = `${environment.SERVER_URL}gettoken`; // Update with your API endpoint for deletingß

      this.http.get<TokenResponse>(getTokenUrl).subscribe({
        next: (response) => {
          const token = JSON.parse(JSON.stringify(response))['token']
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          });
          const payload = {
            "message": {
              "topic": topic,
              "notification": {
                "title": item.title,
                "body": item.summary,
                "image": item.images
              },
              "android": {
                "notification": {
                  "image": item.images
                },
              },
              "apns": {
                "payload": {
                  "aps": {
                    "mutable-content": 1
                  }
                }
              },
              "data": {
                "news_id": item.id
                // "imageUrl": item.images,
                // "story_id": "story_12345"
              }
            }
          }
          console.log(payload);

          this.http.post(FirebaseApiUrl, payload, { headers }).subscribe(
            (response) => {
              console.log('Notification sent successfully', response);
            },
            (error) => {
              console.error('Error sending notification', error);
            }
          );
        },
        error: (error) => {
          console.error('Error Saving item:', error);
        }
      });


      const apiUrl = `${environment.SERVER_URL}save_notification/${item.newsType}/${item.timestamp}/${item.id}`; // Update with your API endpoint for deleting

      this.http.get(apiUrl).subscribe({
        next: () => {
          console.log("data saved succesfully");
        },
        error: (error) => {
          console.error('Error Saving item:', error);
        }
      });
    }
    else { }
  }

  deleteItem(item: ApiItem): void {
    if (confirm(`Do you want to delete this article ? \n\n "${item.title}"`)) {
      const apiUrl = `${environment.SERVER_URL}delete/${item.newsType}/${item.timestamp}/${item.id}`; // Update with your API endpoint for deleting

      this.http.delete(apiUrl).subscribe({
        next: () => {
          // Item deleted successfully, update the local data
          const index = this.apiData.findIndex(i => i.id === item.id);
          if (index !== -1) {
            this.apiData.splice(index, 1);
            this.filteredData = [...this.apiData];

            this.assignSerialNumbers();
          }
        },
        error: (error) => {
          console.error('Error deleting item:', error);
        }
      });
    }
  }

  toggleDeletedFlag(item: ApiItem): void {
    const apiUrl = `${environment.SERVER_URL}update/${item.newsType}/${item.timestamp}/${item.id}`; // Update with your API endpoint for updating

    this.http.put(apiUrl, {}).subscribe({
      next: (response: any) => {
        // Item updated successfully, update the local data
        const index = this.apiData.findIndex(i => i.id === item.id);
        if (index !== -1) {
          this.apiData[index] = { ...item, deleted: response.deleted }; // Update the local item's "deleted" flag
          this.filteredData = [...this.apiData];
          this.assignSerialNumbers();
        }
      },
      error: (error) => {
        console.error('Error updating item:', error);
      }
    });
  }

  updatePagedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.pagedData = this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  // Function to handle page change event
  pageChanged(event: any) {
    this.currentPage = event;
    this.updatePagedData();
  }

  loadCategories(): void {
    this.service.getCategories().subscribe(
      (categories) => {
        this.categories = categories;
        console.log(this.categories)
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }


  logout(): void {
    this.service.logout();
    this.router.navigate(['/login']); // Optional: navigate to login page after logout
  }

}